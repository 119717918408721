import { io } from "socket.io-client";
import { store } from '../store/store'

var socket;

export const connect = () => {
    const session = sessionStorage.getItem("user");
    const user = JSON.parse(session);
    if(socket) {
        return
    }
    socket = io(window.location.origin, {//window.location.origin https://td-integration-td-dev.int.ocp.dev.lafargeholcim-go.com //
        reconnectionDelayMax: 10000,
        query: {
            token: user.token,
        },
        withCredentials: true,
        transports: ["websocket", "polling"]
    });

    setUpHandlers();

    socket.io.on("reconnect", (attempt) => {
        for (var filter in store.state.filters) {
            for (var filterValue of store.state.filters[filter]) {
                if (filter != 'deliveryNoteStatus')
                    add(filter, filterValue)
            }
        }
    });

}

export const add = (type, id) => {
    socket.emit("join-room", {
        type: type,
        id: id
    });
}

export const remove = (type, id) => {
    socket.emit("exit-room", {
        type: type,
        id: id
    });
    store.commit('recalcTrucks');
}

export const setPlant = (plant) => {
    store.commit('reset');
    socket.emit("switch-plant", {
        plant:plant
    });
}

export const truckChecked = (truck) => {
    socket.emit('truck-checked', truck);
}

export const truckUnChecked = (truck) => {
    socket.emit('truck-unchecked', truck);
}

export const deliveryChecked = (delivery) => {
    socket.emit('delivery-checked', delivery);
}
export const leaveRooms = () => {
    socket.emit('reset-rooms');
}
export const checkAllDeliveries= (data) => {
    // TODO pasar filtros y hacer llamada en backend
    socket.emit('triggerAll-dn', data);

}
export const checkAllTrucks = (data) => {
    socket.emit('triggerAll-truck', data);

}

export const deliveryUnchecked = (delivery) => {
    socket.emit('delivery-unchecked', delivery);
}

const setUpHandlers = () => {

    socket.on('deliveryNotes', (data) => {
        store.commit('addTruckDN', data)
    })
    socket.on('trucks', (data) => {
        store.commit('addTruck', data)
    })
    // socket.on('deliveryNotesAll', (data) => {
    //     data.forEach(element => {
    //         store.commit('addTruckDN', element)
    //     });
    // })
    // socket.on('trucksAll', (data) => {
    //     console.log(data, 'dataaaa')
    //     store.commit('setTotalTrucks', {trucks: data.length, perPage: 10})
    // })
    socket.on('availablePlants', (data) => {
        store.commit('availablePlants', data)
    })
    socket.on('filterType', (data) => {
        store.commit('filterType', data)
    })
    socket.on('selectedPlant', (data) => {
        store.commit('selectedPlant', data)
    })

}