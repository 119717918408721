import H from '@here/maps-api-for-javascript';
import truckMarkerPng from "../assets/maps/truckMarker.png"
import shiptoMarkerPng from "../assets/maps/shipto.png"
import plantMarkerPng from "../assets/maps/plant.png"
import { store } from "../store/store";
import {mixin }from "../plugins/commons"
import Vue from 'vue'
import moment from 'moment'
var platform, map;
var defaultLayers, mapsearch, router, ui, behavior;
var routeLine;
var markers = [];
var dataPointsG = [];
var plantsMarkers = [];
var pointsToAdd = [];
var showAllPlants = false;
var originMarker = null;
var destinationMarker = null;
var mapCenter = { lat: 48.499998, lng: 23.3833318 };//lat: 40.43629, lng: -3.630887 
var defaultZoom = 4;
var markerZIndex = 1
var mode = null;
var realRoutePolyline= null;
var realRoutePolylineAway= null;
var realRoutePolylineReturn= null;
var realRouteStyleAway="#6EA9CC";
var realRouteStyleReturn="#6eccc4";
var expectedRouteStyle="#166999";
var plannedRouteStyle="#8E8E8E";
var routeBubble = null;
var clusteredProvider;
var visibleTruck = null;
var lastRouteOpenedTruck = null;


const getTruckMarker =  (iconName)=>{
    var imageUrl = `../assets/maps/${iconName}`
  var images =  require.context('../assets/maps/',false, /\.png$/);
  var truckMarkerPngAlt = images('./'+iconName)
    return truckMarkerPngAlt;
}

export const loadMap = async (mapElement, mapMode) => {
    mode = mapMode;//dashboard, mobile, external
    
    if(map){
        destroyMap();

         // Instantiate (and display) a map object:
         map = new H.Map(
            mapElement,
            defaultLayers.vector.normal.map,
            {
                zoom: defaultZoom,
                center: mapCenter,
                padding: { top: 100, left: 100, bottom: 100, right: 100 }
            }
        );
        // Create the default UI:
        ui = H.ui.UI.createDefault(map, defaultLayers);
        behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
    
    
        //set additional buttons to the map
        setMapButtons();
        startClustering()
        window.addEventListener('resize', function () {
            map.getViewPort().resize();
        });
    
        map.addEventListener('pointermove', function (event) {
            if (event.target instanceof H.map.Polyline ) {
                map.getViewPort().element.style.cursor = 'pointer';
            } 
            else if(event.target instanceof H.map.Marker && event.target.draggable){
                map.getViewPort().element.style.cursor = 'grab';
            }
            else {
                map.getViewPort().element.style.cursor = 'auto';
            }
        }, false);
    }else{
        platform = new H.service.Platform({
            apikey: "2x1lIFlaqKcI7pj9Nj6-a72haklLXWILW9LHZ0vB9fI"
        });
        // Obtain the default map types from the platform object
        defaultLayers = platform.createDefaultLayers();
        // Get an instance of the geocoding service:
        mapsearch = platform.getSearchService();
        // Get an instance of the routing service version 8:
        router = platform.getRoutingService(null, 8);
    
        // Instantiate (and display) a map object:
        map = new H.Map(
            mapElement,
            defaultLayers.vector.normal.map,
            {
                zoom: defaultZoom,
                center: mapCenter,
                padding: { top: 100, left: 100, bottom: 100, right: 100 }
            }
        );
       
        // Create the default UI:
        ui = H.ui.UI.createDefault(map, defaultLayers);
        behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
    
         startClustering()
        //set additional buttons to the map
        setMapButtons();
    
        window.addEventListener('resize', function () {
            map.getViewPort().resize();
        });
    
        map.addEventListener('pointermove', function (event) {
            if (event.target instanceof H.map.Polyline ) {
                map.getViewPort().element.style.cursor = 'pointer';
            } 
            else if(event.target instanceof H.map.Marker && event.target.draggable){
                map.getViewPort().element.style.cursor = 'grab';
            }
            else {
                map.getViewPort().element.style.cursor = 'auto';
            }
        }, false);
        
    }
    

    return;
}

const startClustering = (dataPoints=[]) => {
    dataPointsG = dataPoints;
    clusteredProvider = new H.clustering.Provider(dataPoints, {
           clusteringOptions: {
            // eps: 64,
            eps: 32
          },
          theme: {
                getClusterPresentation: function(cluster) {
                    var weight = cluster.getWeight(); 
                    var clusterIconDiv = document.createElement('div');
                    weight = weight.toString()
                    weight < 10 ? weight + '&nbsp;' : weight
                    clusterIconDiv.style.width='35px';
                    clusterIconDiv.style.height='35px';
                    clusterIconDiv.className = 'cluster-icon';
                    clusterIconDiv.innerHTML = '<div class="icon" style="background-color: red;"></div><div class="count">' + weight + '</div>';
                    var countStyle = 'background-color: #355be7; color: white; border-radius: 50%; height: 100%; display: block; text-align: center; line-height: 35px; font-weight: bold; font-size: 1.3em; filter: drop-shadow(rgb(34, 34, 34) 2px 2px 3px);';
    
                    clusterIconDiv.querySelector('.count').setAttribute('style', countStyle);
                    var clusterIcon = new H.map.DomIcon(clusterIconDiv, {
                        size: {w: 48, h: 48},
                        anchor: {x: 24, y: 24}
                    });
                    var clusterMarker = new H.map.DomMarker(cluster.getPosition(), {
                        icon: clusterIcon,
                        min: cluster.getMinZoom(),
                        max: cluster.getMaxZoom()
                    });
                    clusterMarker.setData(cluster);
                    return clusterMarker;
                },
            getNoisePresentation: function(noisePoint) {
                let data = noisePoint.getData().icon
                var markerHtml = getDomMarkerHTML(data.marker_src, `td_truck_label td_truck_label_${data.truck_plate}`, data.truck_plate, data.marker_id);
                var truckImage = new H.map.DomIcon(markerHtml, {
                    onAttach: function (clonedElement, domIcon, domMarker) {
                        rotateTruck(domMarker, domMarker.getData().rotation_degrees);
                        highlightTruck(visibleTruck)
                        setLabelColor(domMarker)
                        setInfoWindow(domMarker, domMarker.getData(), {lat:domMarker.getData().position_latitude, lng:domMarker.getData().position_longitude}, );
                    },
                    onDetach: function (clonedElement, domIcon, domMarker) {
                    }
                })
                let truck_marker = new H.map.DomMarker(noisePoint.getPosition(), {
                    icon: truckImage,
                    zIndex: markerZIndex++,
                    data: Object.assign({}, data),
                    min: noisePoint.getMinZoom(),
                    
                })
                return truck_marker;
            }
            
          }   
          })
          var clusteringLayer = new H.map.layer.ObjectLayer(clusteredProvider);
         map.addLayer(clusteringLayer);
}
const destroyMap = () => {

    map.removeObjects(map.getObjects());
    map.dispose();
    ui.dispose();
    behavior.dispose();
    markers=[]
}
const setMapButtons = () => {
    const inherits = function (childCtor, parentCtor) {
        function tempCtor() { } tempCtor.prototype = parentCtor.prototype; childCtor.superClass_ = parentCtor.prototype; childCtor.prototype = new tempCtor(); childCtor.prototype.constructor = childCtor; childCtor.base = function (me, methodName, var_args) {
            var args = new Array(arguments.length - 2);
            for (var i = 2; i < arguments.length; i++) {
                args[i - 2] = arguments[i];
            }
            return parentCtor.prototype[methodName].apply(me, args);
        };
    };
    
    if (mode != 'mobile'){

    var centerControlUI = function (opt_options) {
        'use strict'; var options = opt_options || {};

        H.ui.Control.call(this);
        this.onButtonClick = this.onButtonClick.bind(this);

        // create a button element   
        this.increaseBtn_ = new H.ui.base.Button({
            'label': '<span class="here_control_label">Focus trucks</span>',
            'onStateChange': this.onButtonClick
        });

        //add the buttons as this control's children   
        this.addChild(this.increaseBtn_);

        this.setAlignment(options['alignment'] || 'top-center');

        this.addClass("here_control_button");

        this.options_ = options;
    };
    inherits(centerControlUI, H.ui.Control);

    centerControlUI.prototype.onButtonClick = function (evt) {
        'use strict'; if (evt.currentTarget.getState() === 'down') {
            centerMap();
        }
    };

    var centerControlBtn = new centerControlUI();
    ui.addControl('centerControlBtn_CustomUI', centerControlBtn);

    var hideRouteUI = function (opt_options) {
        'use strict'; var options = opt_options || {};

        H.ui.Control.call(this);
        this.onButtonClick = this.onButtonClick.bind(this);

        // create a button element   
        this.increaseBtn_ = new H.ui.base.Button({
            'label': '<span class="here_control_label">Clear route</span>',
            'onStateChange': this.onButtonClick
        });

        //add the buttons as this control's children   
        this.addChild(this.increaseBtn_);

        this.setAlignment(options['alignment'] || 'top-center');

        this.addClass("here_control_button");

        this.options_ = options;
    };
    inherits(hideRouteUI, H.ui.Control);

    hideRouteUI.prototype.onButtonClick = function (evt) {
        'use strict'; if (evt.currentTarget.getState() === 'down') {
            removeRoute();
        }
    };

    var hideRouteBtn = new hideRouteUI();
    ui.addControl('hideRouteBtn_CustomUI', hideRouteBtn);
}
}

const getRoute = async (from, to, departureTime) => {
    return new Promise((resolve, reject) => {
        // Create the parameters for the routing request:
        var routingParameters = {
            'routingMode': 'fast',
            'transportMode': 'truck',
            // The start point of the route:
            'origin': from,
            // The end point of the route:
            'destination': to,
            // Include the route shape in the response
            'return': ['polyline', 'typicalDuration', 'summary', 'travelSummary', 'incidents', 'routingZones'],
            // Set the departure time
            'departureTime': departureTime ? moment(departureTime).toISOString() : moment().toISOString(),
        };
        // Define a callback function to process the routing response:
        var onResult = function (result) {
            resolve(result.routes[0])
        };
        // Call calculateRoute() with the routing parameters,
        // the callback and an error callback function (called if a
        // communication error occurs):
        router.calculateRoute(routingParameters, onResult,
            function (error) {
                reject(error.message);
            });
    })
}

const getRouteWaypoints = async (coords, departureTime)=>{
    return new Promise((resolve, reject) => {
        var from = coords[0].lat + "," + coords[0].lng;
        var to = coords[coords.length-1].lat + "," + coords[coords.length-1].lng;
        var intermediateWaypoints = [];
        for(var i=1;i<coords.length-1;i++){
            intermediateWaypoints.push(coords[i].lat + "," + coords[i].lng);
        }

        // Create the parameters for the routing request:
        var routingParameters = {
            'routingMode': 'fast',
            'transportMode': 'truck',
            // The start point of the route:
            'origin': from,
            // The end point of the route:
            'destination': to,
              // defines multiple waypoints
            'via': new H.service.Url.MultiValueQueryParameter(intermediateWaypoints),
            // Include the route shape in the response
            'return': ['polyline', 'typicalDuration', 'summary', 'travelSummary', 'incidents', 'routingZones'],
            // Set the departure time
            'departureTime': departureTime ? moment(departureTime).toISOString() : moment().toISOString(),
        };
        // Define a callback function to process the routing response:
        var onResult = function (result) {
            resolve(result.routes[0])
        };
        // Call calculateRoute() with the routing parameters,
        // the callback and an error callback function (called if a
        // communication error occurs):
        router.calculateRoute(routingParameters, onResult,
            function (error) {
                reject(error.message);
            });
    })
}

const getLocationFromAddress = async (address) => {
    return new Promise((resolve, reject) => {
        mapsearch.geocode({
            q: address
        }, (result) => {
            // Add a marker for each location found
            var item = result.items[0]
            if (!item) { reject("Address not found"); return }
            //map.addObject(new H.map.Marker(item.position));
            var returned = item.position.lat + "," + item.position.lng;
            resolve(returned);
        }, reject);
    })

}


export const setTruckMarker = (position, metadata) => {

    position.lat = parseFloat(position.lat)
    position.lng = parseFloat(position.lng)
    // position = new google.maps.LatLng(position.lat, position.lng)
    var truck_marker = markers.find(x=>x.getData().truck_plate == metadata.truck_plate);
    clusteredProvider.setDataPoints([])
    dataPointsG = [];
    if (!truck_marker) {
        var markerId = Math.floor(Math.random() * 1000000);
        var marker_src = getTruckMarker(metadata.icon);
        metadata.mode = metadata.mode;
        metadata.marker_id = markerId;
        metadata.marker_src = marker_src;
        metadata.rotate_tries = 0;
        metadata.label_color_tries = 0;
        metadata.rotation_degrees = 180;
        metadata.pendingTime = 0;
        metadata.pendingLength = 0;

        // Create an icon object, an object with geographic coordinates and a marker:
        var markerHtml = getDomMarkerHTML(marker_src, `td_truck_label td_truck_label_${metadata.truck_plate}`, metadata.truck_plate, metadata.marker_id);
        // `
        //     <div class="td_dom_marker"> <div class="td_dom_marker_content">
        //         <img style="width: 60px; height: 60px; " src="${marker_src}" />
        //         <div style="color: rgb(0, 0, 0); font-size: 14px; font-family: Roboto, Arial, sans-serif;" class="td_marker_label td_truck_label td_truck_label_${metadata.truck_plate}">${metadata.truck_plate}</div>
        //     </div>  </div>
        // `;

        var truckImage = new H.map.DomIcon(markerHtml, {
            // the function is called every time marker enters the viewport
            onAttach: function (clonedElement, domIcon, domMarker) {
                rotateTruck(domMarker, domMarker.getData().rotation_degrees);
                highlightTruck(visibleTruck)
                setLabelColor(domMarker)
            },
            // the function is called every time marker leaves the viewport
            onDetach: function (clonedElement, domIcon, domMarker) {
            }
        });
        truck_marker = new H.map.DomMarker(position, {
            icon: truckImage,
            zIndex: markerZIndex++,
            data: Object.assign({}, metadata)
        });

        // map.addObject(truck_marker);
        var dataPoint = new H.clustering.DataPoint(truck_marker.getData().position_latitude, truck_marker.getData().position_longitude, null, {
            icon: Object.assign({}, metadata)
        });
         markers.push(truck_marker);
        store.commit('setMarker', truck_marker)
    } else {
        metadata.marker_src = truck_marker.getData().marker_src;
        metadata.marker_id = truck_marker.getData().marker_id;
        metadata.mode = truck_marker.getData().mode;
        metadata.rotate_tries = 0;
        metadata.rotation_degrees = truck_marker.getData() .rotation_degrees;
        metadata.label_color_tries = 0;
        metadata.pendingTime = truck_marker.getData().pendingTime;
        metadata.pendingLength = truck_marker.getData().pendingLength;
        truck_marker.setData(Object.assign({}, metadata));
    }


    if (mode != 'mobile')
        setInfoWindow(truck_marker, metadata, position);

    if (truck_marker.getGeometry().lat != position.lat || truck_marker.getGeometry().lng != position.lng) {
        moveTruck(truck_marker, position);
    }

    // if (mode == 'mobile') {
    //     setRoute(truck_marker);

    // }
    return truck_marker;


}

const setInfoWindow = (truck_marker, metadata, position) => {
    var viewrouteBtnId = Math.floor(Math.random() * 1000000);
    var infowindowList = `
        Truck plate: ${metadata.truck_plate} <br>
        ${metadata.position_timestamp ? `Last position timestamp: ${moment(metadata.position_timestamp).format("DD/MM/YYYY HH:mm")}<br>` : ''}
        ${metadata.last_event ? `Last event: ${ mixin.methods.getEventFromEventName(metadata.last_event)}<br>` : ''}
        ${metadata.dn_number ? `Delivery note number: ${metadata.dn_number}<br>` : ''}
        ${metadata.plant_name ? `Plant: ${metadata.plant_name}<br>` : ''}
        ${metadata.ship_to_name ? `Delivery address: ${metadata.ship_to_name}<br>` : ''}
        ${metadata.sold_to_name ? `Customer: ${metadata.sold_to_name}<br>` : ''}
        ${metadata.carrier_name ? `Carrier: ${metadata.carrier_name}<br>` : ''}
        ${metadata.forwarding_agent_name ? `Forwarding agent: ${metadata.forwarding_agent_name}<br>` : ''}
    `;
    var infoWindowContent = `
            <div class="content">
                <div class="bodyContent">
                    <span class="infowindow-list">
                        ${infowindowList}
                    </span>
                    ${metadata.dn_number ? `<a href="javascript:void(0)" style="color: black !important; font-weight: bold;" class="view-route-${viewrouteBtnId}" data-truckplate="${metadata.truck_plate}">View route</a>` : ''}
                    
                </div>
            </div>
            `
        ;
    if (!truck_marker.infoWindow) {
        truck_marker.infoWindow = new H.ui.InfoBubble(position, {
            // read custom data
            content: infoWindowContent
        });
        ui.addBubble(truck_marker.infoWindow);
        truck_marker.infoWindow.close();

        truck_marker.addEventListener("tap", async () => {
            for (let marker of markers) {
                marker.infoWindow.close();
            }
            // ui.getBubbles().forEach(bub => ui.removeBubble(bub));

            truck_marker.setZIndex(markerZIndex++)

            ui.addBubble(truck_marker.infoWindow);

            truck_marker.infoWindow.open();

            // google.maps.event.addListener(truck_marker.infoWindow, 'domready', function () {
            if(document.querySelector(`.view-route-${viewrouteBtnId}`))
                document.querySelector(`.view-route-${viewrouteBtnId}`).addEventListener("click", (e) => {
                    setRoute(truck_marker, true);
                });
            // });
            
            centerMap(truck_marker);
        });
    } else {
        var el = truck_marker.infoWindow.getContentElement();
        // var el = document.createElement('span');
        // el.innerHTML = currentContent;
        var infolist = el.querySelector('.infowindow-list')
        infolist.innerHTML = infowindowList;
        truck_marker.infoWindow.setContent(el.innerHTML)
        truck_marker.infoWindow.setPosition(position);

    }

}

export const removeTruckMarker = (metadata) => {
    var truck_marker = null;
    for (var marker of markers) {
        if (marker.getData().truck_plate == metadata.truck_plate) {
            truck_marker = marker;
            break;
        }
    }

    if (!truck_marker) return;

    try{
        map.removeObject(truck_marker)
    }catch(err){//marker not found in map
    }
    markers = markers.filter(x => x.getData().truck_plate != metadata.truck_plate);

    if (metadata.truck_plate == lastRouteOpenedTruck) {
        removeRoute();
    }

}
export const viewRoute = async (truck_plate, dn_number) => {
    var truck_marker = null;
    for (var marker of markers) {
        if (marker.getData().truck_plate == truck_plate) {
            truck_marker = marker;
            break;
        }
    }
    if(truck_marker){
        if(dn_number){
            truck_marker.getData().dn_number = dn_number;
        }
        setRoute(truck_marker, true);
    }
   

    //TODO pintar ruta real seguida hasta ahora para este camion
}
export const highlightTruck = async(truck_plate)=>{
    // visibleTruck = truck_plate
    // for (var marker of markers) {
    //     try{
    //         var markerId = marker.getData().marker_id;
    //         var truckEl = document.getElementById(markerId);
            
    //         if (marker.getData().truck_plate == truck_plate || !truck_plate) {
    //             truckEl.parentElement.style["opacity"] = `1`;
    //         }else{
    //             truckEl.parentElement.style["opacity"] = `0`;
    //         }
    //     }catch(err){}
    // }
  
}
export const setTruckPlateRoute = async(truck_plate, paintRealOrigin)=>{
    var truck_marker = null;
    for (var marker of markers) {
        if (marker.getData().truck_plate == truck_plate) {
            truck_marker = marker;
            break;
        }
    }
    setRoute(truck_marker, paintRealOrigin);
}
const setRoute = async (truck_marker, paintRealOrigin) => {
    var response = await Vue.prototype.$http.get(`api/geolocation/truck/${truck_marker.getData().plant}/route/${truck_marker.getData().truck_plate}/delivery/${truck_marker.getData().dn_number}`);
    var dataResponse = response.data;

    var originLocation = null;
   
        originLocation = `${truck_marker.getGeometry().lat},${truck_marker.getGeometry().lng}`
    
    // else {
    //     if (!dataResponse.origin.position_latitude || !dataResponse.origin.position_longitude) {
    //         originLocation = await getLocationFromAddress(dataResponse.origin.address)
    //     } else {
    //         originLocation = `${dataResponse.origin.position_latitude},${dataResponse.origin.position_longitude}`
    //     }
    // }


    var destinationLocation = null;
    if (!dataResponse.destination.position_latitude || !dataResponse.destination.position_longitude) {
        destinationLocation = await getLocationFromAddress(dataResponse.destination.address)
    } else {
        destinationLocation = `${dataResponse.destination.position_latitude},${dataResponse.destination.position_longitude}`
    }

    if (routeLine && routeLine.length > 0) {
        routeLine.forEach((line) => {
            map.removeObject(line)
        });
        
        routeLine = null;
    }

    var directions = await getRoute(originLocation, destinationLocation);
    // console.log('directions',directions)
    // directions.sections[0].summary.duration
    // directions.sections[0].summary.length
    truck_marker.getData().pendingTime = directions.sections[0].summary.duration
    truck_marker.getData().pendingLength = directions.sections[0].summary.length
    store.commit('setMarker', truck_marker)
    setDirections(directions, expectedRouteStyle)

    lastRouteOpenedTruck = truck_marker.getData().truck_plate;

    if (originMarker) {
        map.removeObject(originMarker);
    }

    if (paintRealOrigin) {

        var originHtml = getDomMarkerHTML(dataResponse.origin.type == 'shipto' ? shiptoMarkerPng : plantMarkerPng, 'td_site_label', dataResponse.origin.name)
        // `
        //         <div class="td_dom_marker">
        //         <div class="td_dom_marker_content">
        //             <img style="width: 60px; height: 60px; " src="${dataResponse.origin.type == 'shipto' ? shiptoMarkerPng : plantMarkerPng}" />
        //             <div style="color: rgb(0, 0, 0); font-size: 14px; font-family: Roboto, Arial, sans-serif;" class="td_marker_label">${dataResponse.origin.name}</div>
        //             </div>
        //             </div>
        //     `;
        var realOriginLocation = null;
        if (!dataResponse.origin.position_latitude || !dataResponse.origin.position_longitude) {
            realOriginLocation = await getLocationFromAddress(dataResponse.origin.address)
        } else {
            realOriginLocation = `${dataResponse.origin.position_latitude},${dataResponse.origin.position_longitude}`
        }
        var originImage = new H.map.DomIcon(originHtml);
        originMarker = new H.map.DomMarker(stringToLatlng(realOriginLocation), {
            icon: originImage,
            zIndex: markerZIndex - 1,
        });

        if(dataResponse.origin.type != 'plant' || !showAllPlants)
            map.addObject(originMarker);
    }

    if (destinationMarker) {
        map.removeObject(destinationMarker);
    }
    var destinationHtml = getDomMarkerHTML(dataResponse.destination.type == 'shipto' ? shiptoMarkerPng : plantMarkerPng, 'td_site_label', dataResponse.destination.name)

    var destinationImage = new H.map.DomIcon(destinationHtml);
    destinationMarker = new H.map.DomMarker(stringToLatlng(destinationLocation), {
        icon: destinationImage,
        zIndex: markerZIndex - 1,
    });

    if(dataResponse.destination.type != 'plant' || !showAllPlants)
        map.addObject(destinationMarker);

    if(paintRealOrigin){
       
        var style="";
        clearRealRoute();

        if(dataResponse.returnToPlantRoute){

            setRealRoute(dataResponse.returnToPlantRoute, 'return');
        }

        if(dataResponse.travelToJobsiteRoute){

            setRealRoute(dataResponse.travelToJobsiteRoute, 'away');
        }

       
       
        // if(coords.length != 0)
        //     setRealRoute(coords, dataResponse.current);
    }

    centerMap(truck_marker);
    truck_marker.setZIndex(markerZIndex++)

     document.querySelectorAll('.td_truck_label').forEach((el) => { el.style.border = 'none'; })
    //console.log('querySelector', `.td_truck_label_${truck_marker.getData().truck_plate}`)
    document.querySelector(`.td_truck_label_${truck_marker.getData().truck_plate}`).style.border = '2px dashed black';
    window.dispatchEvent(new Event('resize'));
}



const setDirections = (directions,  style) => {
    routeLine = [];
    directions.sections.forEach((section) => {
        // Create a linestring to use as a point source for the route line
        let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);

        // Create a polyline to display the route:
        routeLine.push(new H.map.Polyline(linestring, {
            style: { lineWidth: 6, strokeColor: style }
        }));

        // Add the route polyline and the two markers to the map:
        map.addObjects([routeLine[routeLine.length - 1]]);

        
        addPolylineInfoBubble(routeLine[routeLine.length - 1], "Expected route")

        // // Set the map's viewport to make the whole route visible:
        // map.getViewModel().setLookAtData({ bounds: routeLine.getBoundingBox() });

    });
}

export const removeRoute = () => {
     document.querySelectorAll('.td_truck_label').forEach((el) => { el.style.border = 'none'; })
    //map.removeObjects([routeLine, originMarker, destinationMarker]);
    // console.log("removeRoute",routeLine,originMarker,destinationMarker, map.getObjects());
    if (routeLine && routeLine.length > 0) {
        routeLine.forEach((line) => {
            try{map.removeObject(line)}catch(err){}
        });
        
        routeLine = null;
    }
    
    if(routeBubble){
        routeBubble.close();
        routeBubble = null;
    }

    if(realRoutePolyline){
        try{map.removeObject(realRoutePolyline)}catch(err){}
        realRoutePolyline = null;
    }

    if(realRoutePolylineAway){
        try{map.removeObject(realRoutePolylineAway)}catch(err){}
        realRoutePolylineAway = null;
    }

    if(realRoutePolylineReturn){
        try{ map.removeObject(realRoutePolylineReturn)}catch(err){}
        realRoutePolylineReturn = null;
    }


      
    if (originMarker) {
        try{map.removeObject(originMarker)}catch(err){}
        originMarker = null;
    }

    if (destinationMarker) {
        try{map.removeObject(destinationMarker)}catch(err){}
        destinationMarker = null;
    }

}

const moveTruck = (truckMarker, toLatLng) => {

    var oldPosition = truckMarker.getGeometry();
    var heading = computeHeading(oldPosition, toLatLng);
    rotateTruck(truckMarker, Math.round(heading));


    var i = 0;
    var numDeltas = 10;
    var delay = 40; //milliseconds
    var deltaLat = (toLatLng.lat - truckMarker.getGeometry().lat) / numDeltas;
    var deltaLng = (toLatLng.lng - truckMarker.getGeometry().lng) / numDeltas;

    function animateMarker() {
        var latlng = { lat: truckMarker.getGeometry().lat + deltaLat, lng: truckMarker.getGeometry().lng + deltaLng };
        truckMarker.setGeometry(latlng);
        if (i != numDeltas) {
            i++;
            setTimeout(animateMarker, delay);
        } else {
            truckMarker.setGeometry(toLatLng);
        }
    }
    animateMarker()
}

const computeHeading = (start, dest) => {
    var startLat = start.lat;
    var startLng = start.lng;
    var destLat = dest.lat;
    var destLng = dest.lng;

    var toRadians = (degrees) => {
        return degrees * Math.PI / 180;
    };
    var toDegrees = (radians) => {
        return radians * 180 / Math.PI;
    }
    startLat = toRadians(startLat);
    startLng = toRadians(startLng);
    destLat = toRadians(destLat);
    destLng = toRadians(destLng);

    var y = Math.sin(destLng - startLng) * Math.cos(destLat);
    var x = Math.cos(startLat) * Math.sin(destLat) -
        Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    var brng = Math.atan2(y, x);
    var brng = toDegrees(brng);
    return (brng + 360) % 360;

}
const setLabelColor = (truckMarker) => {
    if(truckMarker.getData().mode == 'truck'){
        return;
    }

    var markerId = truckMarker.getData().marker_id;
    var truckEl = document.getElementById(markerId);

    if (!truckEl) {
        setTimeout(() => {
            if (truckMarker.getData().label_color_tries < 5) {
                truckMarker.getData().label_color_tries++;
                setLabelColor(truckMarker)
            }
        }, 10);
        return;
    }//Truck marker is not loaded in DOM instantly

    var labelEl =truckEl.nextElementSibling;

    var lastEvent = truckMarker.getData().last_event;
    
    var color =  mixin.methods.getColorFromEventName(lastEvent)
    labelEl.style.backgroundColor = color;
    labelEl.style.color = mixin.methods.getTextColor(color);

}

const rotateTruck = (truckMarker, degrees) => {
    // console.log(`rotating ${truckMarker.getData().truck_plate} to ${degrees} (${truckMarker.getData().marker_src})`)

    truckMarker.getData().rotation_degrees = degrees;

    var markerId = truckMarker.getData().marker_id;
    var truckEl = document.getElementById(markerId);

    if (!truckEl) {
        setTimeout(() => {
            if (truckMarker.getData().rotate_tries < 5) {
                truckMarker.getData().rotate_tries++;
                rotateTruck(truckMarker, degrees)
            }
        }, 10);
        return;
    }//Truck marker is not loaded in DOM instantly


    degrees = degrees - 180; //truck image front is pointing down.
    truckEl.style["transform"] = `rotate(${degrees}deg)`;

    // //Apply truck shadow
    // var parentEl = truckEl.parentElement;
    // parentEl.style["filter"] = 'drop-shadow(2px 2px 3px #222)';
    // parentEl.style["-webkit-filter"] = 'drop-shadow(2px 2px 3px #222)';
    truckEl.style["filter"] = 'drop-shadow(2px 2px 3px #222)';
    truckEl.style["-webkit-filter"] = 'drop-shadow(2px 2px 3px #222)';

}

var stringToLatlng = (coords) => {
    var lat = parseFloat(coords.split(',')[0])
    var lng = parseFloat(coords.split(',')[1])
    return { lat: lat, lng: lng }
}
const getDomMarkerHTML = (src, labelClass, label, markerId) => {
    var html = `
    <div class="td_dom_marker" draggable="false" style="width:45px; height:45px;">
        <div class="td_dom_marker_content">
            <img draggable="false" id="${markerId}" class="td_dom_marker_image td_dom_marker_image_${markerId}" style="width: 45px; height: 45px; position: fixed;left: -50%;top: -50%;" src="${src}" />
            <div style="color: rgb(0, 0, 0); font-size: 11px; font-family: Roboto, Arial, sans-serif;" class="td_marker_label ${labelClass}">${label}</div>
        <div>
    </div>
    `;
    return html;
}

const setMaxZoom = (zoomLevel) => {
    if (!zoomLevel) zoomLevel = 22;
    defaultLayers.vector.normal.map.setMax(zoomLevel);
}

const fitToBounds = (objects) => {
    //new H.map.Marker({lat:52.5166, lng:13.3833});
    var bounds = new H.map.Group();
    for (var i = 0; i < objects.length; i++) {  
        var tmpMarker = new H.map.Marker(objects[i].getGeometry());
        bounds.addObject(tmpMarker);
    }
    map.addObject(bounds);
    map.getViewModel().setLookAtData({ bounds: bounds.getBoundingBox() });
    map.removeObject(bounds);

}

export const calculateDNVisibility = (statusFilter, showInactive, checkedTrucks, closeMarkers) => {
    //console.log('calculateDNVisibility')
    if (closeMarkers){
        for (let marker of markers) {
            marker.infoWindow.close();
        }
    }

    if(checkedTrucks.length == 0){
        // checkedTrucks = null;
    }
    clusteredProvider.setDataPoints([])
    //dataPointsG = [];
    for (var marker of markers) {
        var marker_truck_plate = marker.getData().truck_plate;
        var dataPoint = new H.clustering.DataPoint(marker.getData().position_latitude, marker.getData().position_longitude, null, {
            icon: marker.data
        });
        //console.log('last_event',marker.getData().truck_plate, marker.getData().last_event)
        if (statusFilter.includes(marker.getData().last_event) || statusFilter.includes('all')) {
            if(showInactive){
                if(!checkedTrucks || checkedTrucks.includes(marker_truck_plate)){
                    //clusteredProvider.addDataPoint(dataPoint);
                    pointsToAdd.push(dataPoint)
                    //dataPointsG.push(marker)
                    marker.setVisibility(true)
                } else {
                    marker.setVisibility(false);
                }
            }else{
                if(mixin.methods.isDeliveryActive(marker.getData().last_event,marker.getData().last_event_timestamp)){
                    if(!checkedTrucks || checkedTrucks.includes(marker_truck_plate)){
                        marker.setVisibility(true)
                        //clusteredProvider.addDataPoint(dataPoint);
                        pointsToAdd.push(dataPoint)
                        //dataPointsG.push(marker)
                    } else {
                        marker.setVisibility(false);
                    }
                } else {
                    marker.setVisibility(false);
                }
            }
        }
    }
    clusteredProvider.setDataPoints(pointsToAdd) 
    pointsToAdd = []
}
export const calculateTruckVisibility = (showInactive, checkedTrucks, closeMarkers) => {
    // console.log('calculateDNVisibility')
    if (closeMarkers){
        for (let marker of markers) {
            marker.infoWindow.close();
        }
    }
    if(checkedTrucks.length == 0){
        // checkedTrucks = null;
    }

    clusteredProvider.setDataPoints([])
    //dataPointsG = [];
    for (var marker of markers) {
        var marker_truck_plate = marker.getData().truck_plate;
        var dataPoint = new H.clustering.DataPoint(marker.getData().position_latitude, marker.getData().position_longitude, null, {
            icon: marker.getData()
        });
        if(showInactive){
            if(!checkedTrucks || checkedTrucks.includes(marker_truck_plate)){
                //clusteredProvider.addDataPoint(dataPoint)
                //dataPointsG.push(marker)
                pointsToAdd.push(dataPoint)
                marker.setVisibility(true)
            } else {
                marker.setVisibility(false)
            }
        }else{
            if(mixin.methods.isTruckActive(marker.getData().position_timestamp)){
                if(!checkedTrucks || checkedTrucks.includes(marker_truck_plate)){
                     marker.setVisibility(true)
                    //clusteredProvider.addDataPoint(dataPoint)
                    //dataPointsG.push(marker)
                    pointsToAdd.push(dataPoint)
                }else{
                    marker.setVisibility(false)
                }
            }else{
                marker.setVisibility(false)
            }
        }
          
           
          
    }
    clusteredProvider.setDataPoints(pointsToAdd)
    pointsToAdd = []
}

export const getTravelInfoTruck = (truck_plate)=>{
    var truck_marker = null;
    for (var marker of markers) {
        if (marker.getData().truck_plate == truck_plate) {
            truck_marker = marker;
            break;
        }
    }
    // var travelInfo = {};
    // travelInfo.pendingTime=truck_marker.getData().pendingTime;
    // travelInfo.pendingLength=truck_marker.getData().pendingLength;
    return truck_marker.getData();
}
export const centerMap = (truck_marker) => {
    if (truck_marker) {
        setMaxZoom(15);
        if(originMarker){
            fitToBounds([originMarker, destinationMarker, truck_marker]);
        }else if(destinationMarker){
            fitToBounds([destinationMarker, truck_marker]);
        }else{
            fitToBounds([truck_marker]);
        }
        

        setMaxZoom(undefined);
    } else {
        // map.setData({ maxZoom: 15 })//TODO
        setMaxZoom(15)
        var bounds = [];
        for (var marker of markers.filter(x => x.getVisibility())) {
            bounds.push(marker);
        }
        for(var marker of plantsMarkers){
            bounds.push(marker);
         }
         for(var marker of dataPointsG){
            bounds.push(marker);
        }
        
        if (markers.filter(x => x.getVisibility()).length == 0 && bounds.length == 0) {
            bounds.push(new H.map.Marker(mapCenter));
            // map.setData({ maxZoom: defaultZoom })//TODO
            setMaxZoom(defaultZoom)
        }
       
       
        fitToBounds(bounds);
        setMaxZoom(undefined)
    }

}

export const focusTruck = (truck_plate) => {
    var truck_marker = null;
    for (var marker of markers) {
        if (marker.getData().truck_plate == truck_plate) {
            truck_marker = marker;
            break;
        }
    }

    setMaxZoom(15)

    fitToBounds([truck_marker])

    setMaxZoom(undefined)

    truck_marker.setZIndex(markerZIndex++)
}
export const showPlants = (plantList) =>{
    removeRoute();
    for(var plant of plantList){
        if(!plant.latitude || !plant.longitude || !plant.name){
            continue;
        }
        var plantHtml = getDomMarkerHTML( plantMarkerPng, 'td_site_label', plant.name)
        var plantLocation = `${plant.latitude},${plant.longitude}`

        var plantImage = new H.map.DomIcon(plantHtml);
        var plantMarker = new H.map.DomMarker(stringToLatlng(plantLocation), {
            icon: plantImage,
            zIndex: markerZIndex - 1,
        });
    
    
        map.addObject(plantMarker);
        plantsMarkers.push(plantMarker)
    }
    showAllPlants = true;
}

export const hideAllPlants = ()=>{
    for(var marker of plantsMarkers){
       try{ map.removeObject(marker) }catch(err){}
    }
    plantsMarkers = []
    showAllPlants = false;
}   

export const clearRealRoute = ()=>{
    if(realRoutePolyline){
        map.removeObject(realRoutePolyline)
        realRoutePolyline = null;
    }
    if(realRoutePolylineAway){
        map.removeObject(realRoutePolylineAway)
        realRoutePolylineAway = null;
    }
    if(realRoutePolylineReturn){
        map.removeObject(realRoutePolylineReturn)
        realRoutePolylineReturn = null;
    }
}
export const setRealRoute = async (coords, routeMode) =>{

    coords.sort(function(a, b) {
        return moment.utc(a.position_timestamp).diff(moment.utc(b.position_timestamp))
    });
    coords = coords.map((x) => {
      return { lat: x.position_latitude, lng: x.position_longitude };
    });

    var lineString = new H.geo.LineString();

    for(var coord of coords){
        lineString.pushPoint({lat:coord.lat, lng:coord.lng});
    }


    switch(routeMode){
        case 'away':
            
            if(realRoutePolylineAway){
                map.removeObject(realRoutePolylineAway)
                realRoutePolylineAway = null;
            }
            realRoutePolylineAway = new H.map.Polyline(
                lineString, { style: { lineWidth: 6,  strokeColor: realRouteStyleAway }}
            );
            
            map.addObject(realRoutePolylineAway);

           addPolylineInfoBubble(realRoutePolylineAway, "Travel to jobsite (Real)")
           
        break;
        case 'return':
            if(realRoutePolylineReturn){
                map.removeObject(realRoutePolylineReturn)
                realRoutePolylineReturn = null;
            }
            realRoutePolylineReturn = new H.map.Polyline(
                lineString, { style: { lineWidth: 6,  strokeColor: realRouteStyleReturn }}
            );
            
            map.addObject(realRoutePolylineReturn);

            addPolylineInfoBubble(realRoutePolylineReturn, "Return to plant (Real)")
        break;
        default:
            if(realRoutePolyline){
                map.removeObject(realRoutePolyline)
                realRoutePolyline = null;
            }
            realRoutePolyline = new H.map.Polyline(
                lineString, { style: { lineWidth: 6,  strokeColor: realRouteStyle }}
            );
            
            map.addObject(realRoutePolyline);
            
            addPolylineInfoBubble(realRoutePolylineReturn, "Real route")
        break;
    }


    




}
const addPolylineInfoBubble = (polyline, content) => {
    polyline.addEventListener("tap", async (e) => {
        if(routeBubble){routeBubble.close(); routeBubble = null;}

        var coord = map.screenToGeo(e.currentPointer.viewportX, e.currentPointer.viewportY)

        routeBubble = new H.ui.InfoBubble(coord,{
            content: content
        });
        routeBubble.addClass ('route-bubble')
        ui.addBubble(routeBubble)
        routeBubble.addEventListener("statechange", async (e) => {
            if(e.target.getState() == 'closed'){
                routeBubble = null;
            }
        });
    });
}
export const setPlantAndShipto = async (plant, shipto) =>{
    
var shiptoLocation = null;
if (!shipto.position_latitude || !shipto.position_longitude) {
    shiptoLocation = await getLocationFromAddress(shipto.address)
} else {
    shiptoLocation = `${shipto.position_latitude},${shipto.position_longitude}`
}
var shiptoHTML = getDomMarkerHTML(shiptoMarkerPng , 'td_site_label', shipto.name)

var shiptoImage = new H.map.DomIcon(shiptoHTML);
destinationMarker = new H.map.DomMarker(stringToLatlng(shiptoLocation), {
    icon: shiptoImage,
    zIndex: markerZIndex - 1,
});


map.addObject(destinationMarker);


var plantLocation = null;
if (!plant.position_latitude || !plant.position_longitude) {
    plantLocation = await getLocationFromAddress(plant.address)
} else {
    plantLocation = `${plant.position_latitude},${plant.position_longitude}`
}
var plantHTML = getDomMarkerHTML(plantMarkerPng , 'td_site_label', plant.name)

var plantImage = new H.map.DomIcon(plantHTML);
originMarker = new H.map.DomMarker(stringToLatlng(plantLocation), {
    icon: plantImage,
    zIndex: markerZIndex - 1,
});

if(!showAllPlants)
   map.addObject(originMarker);

}

export const distanceBetweenWaypoints =  (coords) =>{
    var sumDistance = 0;
    for(var i=0;i<coords.length-1;i++){
        sumDistance += distance(coords[i].lat, coords[i].lng, coords[i+1].lat, coords[i+1].lng);
    }
    return sumDistance;
}

const distance =(lat1, lon1, lat2, lon2) =>{
    var p = 0.017453292519943295;    // Math.PI / 180
    var c = Math.cos;
    var a = 0.5 - c((lat2 - lat1) * p)/2 + 
            c(lat1 * p) * c(lat2 * p) * 
            (1 - c((lon2 - lon1) * p))/2;
  
    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
  }
  function getPolylineLength(polyline) {
    const geometry = polyline.getGeometry();
    let distance = 0;
    let last = geometry.extractPoint(0);
    for (let i=1; i < geometry.getPointCount(); i++) {
      const point = geometry.extractPoint(i);
      distance += last.distance(point);
      last = point;
    }
    if (polyline.isClosed()) {
      distance += last.distance(geometry.extractPoint(0));
    }
    
    // distance in meters
    return distance;
  }